import React, { useRef, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { Link } from 'react-router-dom';

/**
 * Forgot Password component
 *
 * @return {JSX.Element} Forgot Password component
 */
export default function ForgotPassword() {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    /**
     * Logout user
     *
     * @param {Event} event
     */
    async function handleSubmit(event) {
        // Prevent default form submission
        event.preventDefault();

        // Sent password forgot email
        const emailResult = await resetPassword(emailRef.current.value);

        // Check if email was sent
        if (emailResult === true) {
            setError('');
            setMessage('Check your inbox for further instructions');
            setLoading(true);
        } else {
            setError(emailResult);
        }

        setLoading(false);
    }

    return (
        <div className="container container__form position-relative z-1">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="text-center">Password Reset</h1>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input placeholder="Email" type="email" ref={emailRef} required />
                        </div>

                        <div className="form-group text-center">
                            <button disabled={loading} type="submit">
                                Reset Password
                            </button>
                        </div>
                    </form>
                    <div>
                        <Link to="/login">Login</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
