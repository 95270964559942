export const data = [
  {
    id: 1,
    set: 'volkswagen',
    name: 'Volkswagen auto\'s',
    image:
      '/memory/volkswagen.jpg',
    matched: false,
  },
  {
    id: 2,
    set: 'volkswagen',
    name: 'Volkswagen auto\'s',
    image:
      '/memory/volkswagen.jpg',
    matched: false,
  },
  {
    id: 3,
    set: 'rechte-stuk',
    name: 'Rechte stuk circuit',
    image:
      '/memory/rechte-stuk.jpg',
    matched: false,
  },
  {
    id: 4,
    set: 'rechte-stuk',
    name: 'Rechte stuk circuit',
    image:
      '/memory/rechte-stuk.jpg',
    matched: false,
  },
  {
    id: 5,
    set: 'circuit',
    name: 'Circuit Zandvoort',
    image:
      '/memory/circuit-zandvoort.jpg',
    matched: false,
  },
  {
    id: 6,
    set: 'circuit',
    name: 'Circuit Zandvoort',
    image:
      '/memory/circuit-zandvoort.jpg',
    matched: false,
  },
  {
    id: 7,
    set: 'bikes',
    name: 'Gazelle fietsen',
    image:
      '/memory/gazelle-fietsen.jpg',
    matched: false,
  },
  {
    id: 8,
    set: 'bikes',
    name: 'Gazelle fietsen',
    image:
      '/memory/gazelle-fietsen.jpg',
    matched: false,
  },
  {
    id: 9,
    set: 'flags',
    name: 'Pon vlaggen',
    image:
      '/memory/pon-vlaggen.png',
    matched: false,
  },
  {
    id: 10,
    set: 'flags',
    name: 'Pon vlaggen',
    image:
      '/memory/pon-vlaggen.png',
    matched: false,
  },
  {
    id: 11,
    set: 'porsche',
    name: 'Porsche\'s',
    image:
      '/memory/porsches.jpg',
    matched: false,
  },
  {
    id: 12,
    set: 'porsche',
    name: 'Porsche\'s',
    image:
      '/memory/porsches.jpg',
    matched: false,
  },
];
