export const OTHER_KEY = 'Other'
/**
 * Companies
 * 
 * Object of company key value pairs
 */
export function getCompanies() {
  return {
    'A-Point B.V.': 'A-Point B.V.',
    'Alta Nederland BV': 'Alta Nederland BV',
    'Auto Hoogenboom B.V.': 'Auto Hoogenboom B.V.',
    'Auto Muntstad B.V.': 'Auto Muntstad B.V.',
    'Automobielbedrijf Rijnwoud B.V.': 'Automobielbedrijf Rijnwoud B.V.',
    'BBB Cycling': 'BBB Cycling',
    'Bike Mobility Services B.V.': 'Bike Mobility Services B.V.',
    'BSEI': 'BSEI',
    'Compact Rent B.V.': 'Compact Rent B.V.',
    'Cycling Sports Group Europe B.V.': 'Cycling Sports Group Europe B.V.',
    'Exploitatiemaatschappij Rifra B.V.': 'Exploitatiemaatschappij Rifra B.V.',
    'FietsNED B.V.': 'FietsNED B.V.',
    'Huiskes-Kokkeler B.V.': 'Huiskes-Kokkeler B.V.',
    'Koninklijke Gazelle N.V.': 'Koninklijke Gazelle N.V.',
    'Machinefabriek Bolier B.V.': 'Machinefabriek Bolier B.V.',
    'MAN Nederland Dealer B.V.': 'MAN Nederland Dealer B.V.',
    'MAN Rollo B.V.': 'MAN Rollo B.V.',
    'MAN Truck & Bus B.V.': 'MAN Truck & Bus B.V.',
    'ModiForce BV': 'ModiForce BV',
    'Move | House of Mobility B.V.': 'Move | House of Mobility B.V.',
    'Pon Center B.V.': 'Pon Center B.V.',
    'Pon Dealer B.V.': 'Pon Dealer B.V.',
    'Pon Direct Retail B.V.': 'Pon Direct Retail B.V.',
    'Pon Energy Rental B.V.': 'Pon Energy Rental B.V.',
    'Pon Equipment Netherlands': 'Pon Equipment Netherlands',
    'Pon Facilities BV': 'Pon Facilities BV',
    'Pon Holdings B.V.': 'Pon Holdings B.V.',
    'Pon HR Services B.V.': 'Pon HR Services B.V.',
    'Pon IT B.V.': 'Pon IT B.V.',
    'Pon Logistics B.V.': 'Pon Logistics B.V.',
    'Pon Luxury Cars Amsterdam B.V.': 'Pon Luxury Cars Amsterdam B.V.',
    'Pon Luxury Cars B.V.': 'Pon Luxury Cars B.V.',
    'Pon Power B.V.': 'Pon Power B.V.',
    'Pon Rental Netherlands': 'Pon Rental Netherlands',
    'Pon\'s Automobielhandel B.V.': "Pon's Automobielhandel B.V.",
    'Radio Holland Group B.V.': 'Radio Holland Group B.V.',
    'Radio Holland Netherlands B.V.': 'Radio Holland Netherlands B.V.',
    'RH Marine Netherlands': 'RH Marine Netherlands',
    'SITECH Netherlands': 'SITECH Netherlands',
    'Smart Urban Mobility B.V.': 'Smart Urban Mobility B.V.',
    'Topec International B.V.': 'Topec International B.V.',
    'Urus Europe B.V.': 'Urus Europe B.V.',
    'Veloretti B.V.': 'Veloretti B.V.',
    'Verachtert': 'Verachtert',
    [OTHER_KEY]: 'Anders...',
  };
}
