import React, { useState, useEffect } from 'react';
import { quizTypeWhereIsPon } from '../../Constants';
import { useTranslation } from 'react-i18next';

export default function WhereIsPonGame({
  selectedAnswers,
  setSelectedAnswers,
}) {
  const [selectedCell, setSelectedCell] = useState(null);
  const [cellClicked, setCellClicked] = useState(false);
  const { t } = useTranslation('quiz');

  useEffect(() => {
    /**
     * Check if the user has already answered this question
     * and set the selected cell if so
     */
    const savedAnswer = selectedAnswers.find(
      (answer) => answer.question === quizTypeWhereIsPon,
    );
    if (savedAnswer) {
      setSelectedCell(savedAnswer.answer);
      setCellClicked(true);
    }
  }, [selectedAnswers]);

  /**
   * Handles cell click events
   * @param {number} rowIndex - The row index of the clicked cell
   * @param {number} colIndex - The column index of the clicked cell
   */
  const handleCellClick = (rowIndex, colIndex) => {
    // Calculate the cell number based on row and column indices
    const cellNumber = rowIndex * 3 + colIndex + 1;

    // Check if the clicked cell is already selected
    if (selectedCell === cellNumber) {
      // If it is the same cell, deselect it
      setSelectedCell(null);
      setCellClicked(false);
      // Remove the answer from the selectedAnswers array
      setSelectedAnswers(
        selectedAnswers.filter(
          (answer) => answer.question !== quizTypeWhereIsPon,
        ),
      );
    } else {
      // If it is a different cell, select the new cell
      setSelectedCell(cellNumber);
      setCellClicked(true);

      let answers = [...selectedAnswers];
      let answersIndex = answers.findIndex(
        (answer) => answer.question === quizTypeWhereIsPon,
      );

      if (answersIndex < 0) {
        // If no existing answer, add a new one
        answers.push({
          question: quizTypeWhereIsPon,
          answer: cellNumber,
        });
      } else {
        // If there is an existing answer, update it
        answers[answersIndex].answer = cellNumber;
      }

      // Update the selectedAnswers array with the new selection
      setSelectedAnswers(answers);
    }
  };

  return (
    <div
      className={`container where-is-pon ${cellClicked ? 'cell-selected' : ''}`}
    >
      <div className="row">
        <div className="col-12 col-lg-5 d-md-flex justify-content-start align-items-center mb-4 mb-lg-0">
          <div className="image-container">
            <img src="/where-is-pon.jpeg" alt="question" />
            <div className="grid-overlay">
              {[...Array(3)].map((_, rowIndex) => (
                <div className="grid-row" key={`row-${rowIndex + 1}`}>
                  {[...Array(3)].map((_, colIndex) => {
                    const cellNumber = rowIndex * 3 + colIndex + 1;
                    return (
                      <button
                        className={`grid-cell ${cellClicked ? (selectedCell === cellNumber ? 'selected' : 'dimmed') : ''}`}
                        key={`cell-${cellNumber}`}
                        onClick={() => handleCellClick(rowIndex, colIndex)}
                      ></button>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 ps-lg-5 d-flex justify-content-center flex-column">
          <h2 className="title fw-medium w-90 mb-3 where-is-pon-title">
            {t('whereIsPonGame.title')}
          </h2>
          <h2 className="title fw-medium w-90 mb-3">
            {t('whereIsPonGame.subtitle')}
          </h2>
          <p className="description">{t('whereIsPonGame.description')}</p>
        </div>
      </div>
    </div>
  );
}
