import React, { useRef, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

/**
 * Login component
 *
 * @return {JSX.Element} Login component
 */
export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /**
   *
   * @param {Event} event
   */
  async function handleSubmit(event) {
    // Prevent default form submission
    event.preventDefault();

    // Login user
    const loginResult = await login(
      emailRef.current.value,
      passwordRef.current.value
    );

    // Check if the login was successful
    if (loginResult === true) {
      setError('');
      setLoading(true);
      history.push('/export');
    } else {
      setError(loginResult);
    }

    setLoading(false);
  }

  return (
    <div className="container container__form position-relative z-1">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 className="text-center">Log In</h1>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input placeholder="Email" type="email" ref={emailRef} required />
            </div>

            <div className="form-group">
              <input
                placeholder="Password"
                type="password"
                ref={passwordRef}
                required
              />
            </div>

            <div className="form-group text-center">
              <button disabled={loading} className="w-100" type="submit">
                Log In
              </button>
            </div>
          </form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
