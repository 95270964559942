import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { firestore } from '../firebase';

/**
 * Login component
 *
 * @return {JSX.Element} Login component
 */
export default function ExportPage() {
  const [submissions, setSubmissions] = useState([]);
  const [filename, setFilename] = useState('export_quiz.csv');

  // Function to generate the current date and time for the filename
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `export_quiz_${year}-${month}-${day}_${hours}:${minutes}:${seconds}.csv`;
  };

  useEffect(() => {
    // Fetch the quiz data
    const fetchQuizData = async () => {
      const querySnapshot = await firestore.collection('quiz').get();
      const allSubmissions = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const { givenAnswers = [] } = data;
        let correctAnswers = 0;
        let totalQuestions = 0;

        // Function to check if the given answer is correct
        const checkAnswer = (answer, correctAnswer) => {
          if (answer !== undefined && answer !== false) {
            totalQuestions++;
            if (answer === correctAnswer) {
              correctAnswers++;
            }
          }
        };

        // Handle the Puzzle question (Question 1)
        const handlePuzzle = () => {
          const question = givenAnswers[0] || {};
          checkAnswer(question.answer, 'b');
          return {
            question_id_1: question.question,
            question_title_1: 'Puzzle',
            answer_1: question.answer,
          };
        };

        // Handle generic questions (Questions 2, 3, 5 & 7)
        const handleQuestion = (index, correctAnswer) => {
          const question = givenAnswers[index] || {};
          checkAnswer(question.answerChoice, correctAnswer);
          return {
            [`question_id_${index + 1}`]: question.question,
            [`question_title_${index + 1}`]: question.questionTitle,
            [`answer_${index + 1}`]: question.answerChoice,
            [`answer_${index + 1}_value`]: question.answerValue,
          };
        };

        // Handle the Memory question (Question 4)
        // Answer is always correct
        const handleMemory = () => {
          const question = givenAnswers[3] || {};
          totalQuestions++;
          correctAnswers++;
          return {
            question_id_4: question.question,
            question_title_4: 'Memory',
            answer_4_moves: question.answer?.moves,
            answer_4_time: question.answer?.time,
          };
        };

        // Handle the "Where is Pon" question (Question 6)
        const handleWhereIsPon = () => {
          const question = givenAnswers[5] || {};
          checkAnswer(question.answer, 8);
          return {
            question_id_6: question.question,
            question_title_6: 'Where is Pon',
            answer_6: question.answer,
          };
        };

        // Handle the Reaction Game question (Question 8)
        const handleReactionGame = () => {
          const question = givenAnswers[7] || {};
          totalQuestions++;
          if (question.answer?.time !== undefined && question.answer?.time !== false) {
            correctAnswers++;
          }
          return {
            question_id_8: question.question,
            question_title_8: 'Reaction Game',
            answer_8_time: question.answer?.time,
            answer_8_jump_start: question.answer?.jumpStart,
          };
        };

        // Constructing the answers object with all the question data
        const answers = {
          id: doc.id,
          firstname: data.firstname,
          lastName: data.lastName,
          email: data.email,
          company: data.company,
          companyOther: data.companyOther,
          ...handlePuzzle(),
          ...handleQuestion(1, 'c'),
          ...handleQuestion(2, 'c'),
          ...handleMemory(),
          ...handleQuestion(4, 'b'),
          ...handleWhereIsPon(),
          ...handleQuestion(6, 'c'),
          ...handleReactionGame(),
          questionsCorrect: correctAnswers,
          questionsTotal: totalQuestions,
          date: data.date,
          timestamp: data.timestamp,
          timestampString: data.timestampString,
        };

        return answers;
      });

      // Kep't this one in to see all data is added,
      // Prevent's us before pressing the `Download CSV` button too soon
      console.log(allSubmissions);
      setSubmissions(allSubmissions);
    };

    // Fetch the quiz data and set the filename when the component mounts
    fetchQuizData();
    setFilename(getCurrentDateTime());
  }, []);

  return (
    <div className="text-center position-relative z-1">
      <CSVLink
        data={submissions}
        filename={filename}
        className="btn btn-primary"
        target="_blank"
      >
        Download CSV
      </CSVLink>
    </div>
  );
}
