import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { StoryblokComponent, useStoryblokApi } from '@storyblok/react';

import DefaultContentPage from './DeafultContentPage';
import { STORYBLOK_LOCATION, STORYBLOK_PREFIX } from '../Constants';

export function ContentPage() {
  const { t } = useTranslation('content');
  const { pathname } = useLocation();
  const history = useHistory();
  const storyblokApi = useStoryblokApi();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [pageData, setPageData] = useState({});

  // Remove the slash from the pathname
  const slug = pathname.substring(1);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);

    storyblokApi
      .get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}pages/${slug}`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: false,
      })
      .then((res) => {
        setPageData(res?.data?.stories[0]);

        // If the page is not found, redirect to 404.
        if (res?.data?.stories.length === 0) {
          history.push('/404');
        }
      })
      .catch(() => {
        setIsError(true);
      });

    setIsLoading(false);
  }, []);

  if (isError) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <div className="alert rounded alert-danger">
          {t('loading_page_data_error')}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="titlePage fade-in">
        <h1>{t('loading_page_data_pending')}</h1>
      </div>
    );
  }

  if (!pageData?.content) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <div className="alert rounded alert-danger">
          {t('no_content_available')}
        </div>
      </div>
    );
  }

  return (
    <DefaultContentPage title={pageData.name}>
      <StoryblokComponent blok={pageData?.content} />
    </DefaultContentPage>
  );
}
