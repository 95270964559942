import React, { useRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../Alert';
import { useSharedQuizContext } from '../../Contexts/SharedQuizProvider';
import { useTranslation } from 'react-i18next';
import { getCompanies, OTHER_KEY } from '../../data/companies';

const QuizForm = ({ selectedAnswers, submitQuiz, notAllQuestionsAnswered, noAnswers, errors }) => {
  const { user, setUser } = useSharedQuizContext();
  const captchaRef = useRef(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(user.company || '');
  const { t } = useTranslation('quiz');

  const companies = getCompanies();
  const companiesKeys = Object.keys(companies);

  // Reset the captcha when there are errors found
  useEffect(() => {
    if (captchaRef?.current && errors) {
      captchaRef.current.reset();
      setCaptchaValue(false);
      setIsCaptchaVerified(false);
    }
  }, [errors]);

  const checkCaptcha = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(true);
  };

  const getErrorMessage = (type) => {
    if (!errors) return null;
    const hasError = errors?.find((error) => error.type === type);
    return hasError ? <Alert type="danger" message={hasError.text} /> : null;
  };

  return (
    <div className="container position-relative z-3">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          {notAllQuestionsAnswered && <Alert type="danger" message={t('notAllQuestionsAnswered')} />}
          {noAnswers && <Alert type="danger" message={t('noAnswers')} />}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <div className="quiz__form-wrapper">
            <h1 className="text-start">{t('form_title')}</h1>
            <form onClick={(e) => e.preventDefault()}>
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <label htmlFor="firstName">
                      {t('firstName')} <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstname"
                      placeholder={t('firstNamePlaceholder')}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          firstName: e.target.value,
                        });
                      }}
                    />
                    {getErrorMessage('firstName')}
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="lastName">
                      {t('lastName')} <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastname"
                      placeholder={t('lastNamePlaceholder')}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          lastName: e.target.value,
                        });
                      }}
                    />
                    {getErrorMessage('lastName')}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <label htmlFor="email">
                      {t('email')} <sup>*</sup>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder={t('emailPlaceholder')}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value,
                        });
                      }}
                    />
                    {getErrorMessage('email')}
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="company">
                      {t('company')} <sup>*</sup>
                    </label>
                    <select
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedCompany(value);
                        setUser({
                          ...user,
                          company: e.target.value,
                        });
                      }}
                    >
                      <option value="">{t('placeholder_company')}</option>
                      {companiesKeys.map((key) => (
                        <option key={key} value={key}>
                          {companies[key]}
                        </option>
                      ))}
                    </select>
                    {getErrorMessage('company')}
                  </div>
                </div>
              </div>

              {selectedCompany === OTHER_KEY && (
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 mb-3 mb-md-0">
                      <label htmlFor="otherCompany">
                        {t('other_company')} <sup>*</sup>
                      </label>
                      <input
                        type="text"
                        id="otherCompany"
                        name="otherCompany"
                        placeholder={t('other_company_placeholder')}
                        onChange={(e) => {
                          setUser({
                            ...user,
                            companyOther: e.target.value,
                          });
                        }}
                      />
                      {getErrorMessage('companyOther')}
                    </div>
                  </div>
                </div>
              )}

              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 pt-2 ml-0">
                    <ReCAPTCHA
                      sitekey={import.meta.env.VITE_SITE_KEY}
                      ref={captchaRef}
                      onChange={checkCaptcha}
                      hl="nl"
                    />
                    <p className="text-start pt-2 opacity-50">
                      <small>{t('captcha_disclaimer')}</small>
                    </p>
                    {getErrorMessage('captcha')}
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="f1-button-wrapper">
                      <button
                        className="f1-button w-100"
                        onClick={() => {
                          submitQuiz(user, selectedAnswers, captchaValue);
                        }}
                        disabled={!isCaptchaVerified}
                        title={t('submitQuiz')}
                      >
                        <span> {t('submitQuiz')}</span>
                        <img
                          src="/f1-redbull-car.png"
                          className="send-answers-button-image"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizForm;
