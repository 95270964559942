import {
  quizTypeMemory,
  quizTypePuzzle,
  quizTypeQuestion,
  quizTypeReactionGame,
  quizTypeWhereIsPon,
} from '../../Constants';

// The list of questions in order, including the games.
export function getQuizOrder(questions) {
  return [
    {
      type: quizTypePuzzle,
    },
    {
      ...Object.values(questions)[0], // Question 1
      type: quizTypeQuestion,
    },
    {
      ...Object.values(questions)[1], // Question 2
      type: quizTypeQuestion,
    },
    {
      type: quizTypeMemory,
    },
    {
      ...Object.values(questions)[2], // Question 3
      type: quizTypeQuestion,
    },
    {
      type: quizTypeWhereIsPon,
    },
    {
      ...Object.values(questions)[3], // Question 4
      type: quizTypeQuestion,
    },
    {
      type: quizTypeReactionGame,
    },
  ];
}
