import React, { useContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { logErrors } from '../helpers/logErrors';

const AuthContext = React.createContext();

/**
 * Custom hook to use the AuthContext
 * 
 * @return {Context} AuthContext
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * Provides authentication context to its children
 * 
 * @param {object} children React children
 * @return {JSX.Element} Auth provider
 */
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  /**
   * Sign up a new user
   * 
   * @param {string} firstName First name
   * @param {string} lastName Last name
   * @param {string} email Email
   * @param {string} password Password
   * @return {Promise}
   */
  async function signup(firstName, lastName, email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        firestore
          .collection('users')
          .doc(userCredential.user.uid)
          .set({
            firstName,
            lastName
          })
          .then(() => {
            return true;
          })
          .catch((error) => {
            logErrors(error);
          });
      })
      .catch((error) => {
        logErrors(error);
      });
  }

  /**
   * Log in an existing user
   * 
   * @param {string} email Email
   * @param {string} password Password
   * @return {Promise}
   */
  async function login(email, password) {
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logErrors(error);
      });
  }

  /**
   * Log out the current user
   * 
   * @return {Promise}
   */
  async function logout() {
    return auth
      .signOut()
      .then(() => {
        return true;
      })
      .catch((error) => {
        logErrors(error);
      });
  }

  /**
   * Send a password reset email
   * 
   * @param {string} email Email
   * @return {Promise}
   */
  async function resetPassword(email) {
    return auth
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logErrors(error);
      });
  }

  /**
   * Update the current user's email
   * 
   * @param {string} email Email
   * @return {Promise}
   */
  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  /**
   * Update the current user's password
   * 
   * @param {string} password Password
   * @return {Promise}
   */
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // Listen for auth state changes
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      }
      setLoading(false);
    });
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
