import React, { createContext, useContext, useState } from 'react';

const SharedQuizContext = createContext();

export function SharedQuizProvider(props) {
  const [progress, setProgress] = useState(0);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);
  const [user, setUser] = useState({});
  const [activeQuestionType, setActiveQuestionType] = useState('');
  const numberOfQuestions = 8;

  const value = {
    progress,
    setProgress,
    alreadyAnswered,
    setAlreadyAnswered,
    quizFinished,
    setQuizFinished,
    user,
    setUser,
    activeQuestionType,
    setActiveQuestionType,
    numberOfQuestions,
  };

  return (
    <SharedQuizContext.Provider value={value}>
      {props.children}
    </SharedQuizContext.Provider>
  );
}

export function useSharedQuizContext() {
  return useContext(SharedQuizContext);
}
