import React from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

export default function Page({ blok }) {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      {blok.body
        ? blok.body.map((nestedBlok) => (
            <div key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </div>
          ))
        : null}
    </div>
  );
}
