import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ScrollToTop from '../Contexts/ScrollToTop';
import { SharedQuizProvider } from '../Contexts/SharedQuizProvider';
import { AuthProvider } from '../Contexts/AuthContext';
import { PrizesProvider } from '../Contexts/PrizesContext';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import { ContentPage } from '../pages/ContentPage';
import PageNotFound from '../pages/PageNotFound';
import QuizPage from '../pages/QuizPage';
import LoginPage from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ExportPage from '../pages/ExportPage';
import { contentPages } from '../Constants';
import PrivateRoute from '../Components/PrivateRoute';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * This is the main entry-point into the app.
 *
 * @return {JSX.Element} The app container
 */
function App() {
  const showRacingLines = !contentPages.includes(
    window.location.pathname.substring(1),
  );

  return (
    <div
      id="app"
      className={`App ${showRacingLines ? 'position-relative' : ''}`}
    >
      <SharedQuizProvider>
        <PrizesProvider>
          <Router>
            <ScrollToTop />
            <Header />
            <Switch>
              <SentryRoute exact path="/" component={Home} />
              <SentryRoute exact path="/quiz" component={QuizPage} />
              {contentPages.map((page) => (
                <SentryRoute
                  key={page}
                  path={`/${page}`}
                  component={ContentPage}
                />
              ))}
              <AuthProvider>
                <Route path="/login" component={LoginPage} />
                <Route
                  path="/forgot-password"
                  component={ForgotPasswordPage}
                />
                <PrivateRoute path="/export" component={ExportPage} />
              </AuthProvider>
              <SentryRoute path="*" component={PageNotFound} />
            </Switch>
            <Footer showRacingLines={showRacingLines} />
          </Router>
        </PrizesProvider>
      </SharedQuizProvider>
    </div>
  );
}

export default App;
