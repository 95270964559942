import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { apiPlugin, storyblokInit } from '@storyblok/react';

import './styles/index.scss';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import Page from './Components/storyblok/Page';
import Content from './Components/storyblok/Content';

storyblokInit({
  accessToken: import.meta.env.VITE_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    pageTGIP: Page,
    content: Content,
  },
});


const history = createBrowserHistory();

// Only init Sentry if we're in production or development
if (
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1' &&
  window.location.hostname !== ''
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.NODE_ENV,
    release: '2024.1.0.1',
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
