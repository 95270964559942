import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QUIZ_SLUG } from '../Constants';

const FooterLink = ({ newTab, to, children }) => (
  <NavLink
    target={newTab ? '_blank' : '_self'}
    rel="noopener noreferrer"
    to={to}
    className="fw-normal"
  >
    {children}
  </NavLink>
);

/**
 * Footer
 *
 * @return {JSX.Element} Footer component
 */

export default function Footer({ showRacingLines }) {
  const { t } = useTranslation('footer');
  const location = useLocation();

  const isQuizPage = location.pathname === `/${QUIZ_SLUG}`;

  return (
    <footer className={`${showRacingLines ? 'footer-racing-lines' : ''}`}>
      <nav className="d-flex flex-column flex-lg-row justify-content-center align-items-center text-capitalize position-relative z-1">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://digitalsolutions.pon.com"
          className="text-uppercase"
        >
          {t('created_by_ds')}
          <img
            src="/digital-solutions-logo-shape-light.svg"
            alt="Pon Digital Solution"
            width={22}
            height={24}
            className="ms-2"
          />
        </a>
        <div className="d-none d-lg-block divider"></div>
        <div className="nav-items-wrapper">
          <FooterLink newTab={isQuizPage} to="/terms-and-conditions">
            {t('terms_link')}
          </FooterLink>
          <FooterLink newTab={isQuizPage} to="/privacy-statement">
            {t('privacy_link')}
          </FooterLink>
          <FooterLink newTab={isQuizPage} to="/contact">
            {t('contact_link')}
          </FooterLink>
        </div>
      </nav>
    </footer>
  );
}
