import React, { useEffect, useState } from 'react';
import { useSharedQuizContext } from '../../Contexts/SharedQuizProvider';
import Image from '../general/Image';

/**
 * Quiz header
 *
 * @param {object} questionData - The question data
 *
 * @return {JSX.Element} Quiz header component
 */
export default function QuizQuestion({
  questionData,
  changeAnswer,
  quizExpired,
  selectedAnswers,
}) {
  const { alreadyAnswered, quizFinished } = useSharedQuizContext();
  const { image, question } = questionData.content;
  const [givenAnswer, setGivenAnswer] = useState({});
  const answers = [
    questionData.content.answerA,
    questionData.content.answerB,
    questionData.content.answerC,
    questionData.content.answerD,
  ];

  useEffect(() => {
    let answerIndex = selectedAnswers.findIndex(
      (obj) => obj.question === questionData.id,
    );
    if (answerIndex >= 0) {
      setGivenAnswer(selectedAnswers[answerIndex]);
    }
  }, [selectedAnswers, questionData.id]);

  return (
    <section className="quiz__question">
      <div className="container">
        <div className="row">
          {image?.filename && (
            <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center flex-column mb-4 mb-lg-0 quiz__question--image-overlay">
              <figure>
                <Image image={image} />
                {image?.title && (<figcaption className="mt-2 mb-2 mb-lg-0 text-center"><strong>{image?.title}</strong></figcaption>)}
              </figure>
            </div>
          )}
          <div
            className={`col-12 ${image?.filename ? 'col-lg-7 ps-lg-5 d-flex justify-content-center flex-column' : ''}`}
          >
            <div>
              <div className="row">
                <div className="col-12">
                  <h2
                    className="quiz__question--content"
                    dangerouslySetInnerHTML={{
                      __html: question,
                    }}
                  ></h2>
                </div>
              </div>
              {!alreadyAnswered && !quizFinished && !quizExpired && (
                <div className="row">
                  {answers.map((answer, i) => {
                    return (
                      <div key={i} className="col-12 col-lg-6">
                        <button
                          className={`button button--lg rounded answer d-flex align-items-center ${givenAnswer?.answer === i
                            ? 'button--active pop'
                            : 'button--secondary-outline'
                            } ${i !== 3 ? 'mb-2' : 'mb-0'} ${i === 2 ? 'mb-lg-0' : ''}`}
                          onClick={() => changeAnswer(
                            i,
                            questionData.id,
                            questionData.content.question,
                            answer
                          )}
                        >
                          <span className="answer__letter">
                            {['A', 'B', 'C', 'D'][i]}
                          </span>
                          <span className="answer__text">{answer}</span>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
