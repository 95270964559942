import React from 'react';
import { useHistory } from 'react-router-dom';
import DefaultContentPage from './DeafultContentPage';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
    const {t} = useTranslation('page_not_found');
    const history = useHistory();

    function handleClick(e) {
        e.preventDefault();
        history.push('/');
    }
    return (
        <DefaultContentPage title={t('page_not_found_title')}>
            <div className="not-found">
                <p>{t('page_not_found_content')}</p>
                <button className="button button--secondary" onClick={handleClick}>{t('go_back')}</button>
            </div>
        </DefaultContentPage>

    )
}
