import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'nl',
    debug: false,
    detection: {
      order: ['querystring', 'path'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
        useSuspense: false,
        bindI18n: 'languageChanged',
      }
  });

export default i18n;
