import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSharedQuizContext } from '../Contexts/SharedQuizProvider';
import ProgressBar from './quiz/ProgressBar';
import { contentPages } from '../Constants';

/**
 * Header component
 *
 * @return {JSX.Element} Header component
 */
export default function Header() {
  const { progress, numberOfQuestions, alreadyAnswered, quizFinished } =
    useSharedQuizContext();
  const { t } = useTranslation('header');
  const location = useLocation();

  let isHome = false;
  if (location.pathname === '/' || location.pathname.includes('home')) {
    isHome = true;
  }

  let showHeaderImage = true;
  if (
    numberOfQuestions >= progress &&
    !quizFinished &&
    !alreadyAnswered &&
    location.pathname.includes('quiz')
  ) {
    showHeaderImage = false;
  }

  // Hide header image on content pages.
  contentPages.forEach((page) => {
    if (location.pathname.includes(page)) {
      showHeaderImage = false;
    }
  });

  let lastStep = false;
  if (progress > numberOfQuestions) {
    lastStep = true;
  }

  const getHeaderClass = () => {
    if ((alreadyAnswered || quizFinished) && !isHome)
      return 'hero hero--thank-you-page';
    if (isHome) return 'hero hero--home';
    if (lastStep) return 'hero hero--last-step';
    return '';
  };

  return (
    <header className={`${getHeaderClass()} z-2 pt-4 position-relative`}>
      <div className="container">
        <div className="row position-relative z-1">
          <ProgressBar progress={progress} lastStep={lastStep} />
        </div>
      </div>
      {showHeaderImage && (
        <div className="container text-light text-uppercase fw-bold hero__title-wrapper">
          <div className="row" aria-level={1}>
            <div className="col-12 text-left">
              <p className="hero__title--the-game mb-0">{t('title1')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex" aria-level={1}>
              <p className="text-start mb-12 hero__title-is-pon">
                {t('title2')}
              </p>
              <div className="hero__title-edition fw-medium d-flex flex-column justify-content-center ms-2 ms-md-4">
                <p className="mb-0">{t('sub_title1')}</p>
                <p className="mb-0">{t('sub_title2')}</p>
                <p>{t('sub_title3')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
