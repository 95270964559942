/**
 * Get the previous answer for a question.
 *
 * @param {array} selectedAnswers The array of selected answers.
 * @param {string} questionId The ID of the question.
 * @returns
 */
export function getPreviousAnswer(selectedAnswers, questionId) {
  const answersIndex = selectedAnswers.findIndex(
    (obj) => obj.question === questionId,
  );

  if (answersIndex => 0) {
    return selectedAnswers[answersIndex]?.answer;
  }

  return null;
}
