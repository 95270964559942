import React, { createContext, useState, useContext, useCallback } from 'react';
import { useStoryblokApi } from '@storyblok/react';
import { STORYBLOK_LOCATION, STORYBLOK_PREFIX } from '../Constants';

const PrizesContext = createContext();

export const PrizesProvider = ({ children }) => {
  const storyblokApi = useStoryblokApi();

  const [prizes, setPrizes] = useState(null);
  const [isLoadingPrizes, setIsLoadingPrizes] = useState(false);
  const [errorLoadingPrizes, setErrorLoadingPrizes] = useState(false);

  const fetchPrizes = useCallback(() => {
    // Only fetch the prizes if the value is null
    // We added this logic so that we only call the API when the Prizes component is in use
    if (prizes === null) {
      setIsLoadingPrizes(true);
      setErrorLoadingPrizes(false);

      storyblokApi
        .get(STORYBLOK_PREFIX, {
          starts_with: `${STORYBLOK_LOCATION}prizes`,
          version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
          is_startpage: false,
          per_page: 20,
        })
        .then((res) => {
          setPrizes(res?.data?.stories || []);
        })
        .catch(() => {
          setErrorLoadingPrizes(true);
        })
        .finally(() => {
          setIsLoadingPrizes(false);
        });
    }
  }, [prizes]);

  return (
    <PrizesContext.Provider value={{ prizes, isLoadingPrizes, errorLoadingPrizes, fetchPrizes }}>
      {children}
    </PrizesContext.Provider>
  );
};

export const usePrizesContext = () => useContext(PrizesContext);
