import { useEffect, useState, useRef, useCallback } from 'react';
import { quizTypeReactionGame } from '../../Constants';
import { triggerSaEvent } from '../../helpers/simpleAnalytics';

export default function ReactionGame({ selectedAnswers, setSelectedAnswers }) {
  // Consolidated state into a single object to simplify state management and reduce re-renders
  const [gameState, setGameState] = useState({
    gameStarted: false,
    countdown: 5,
    jumpStart: true,
    time: null,
    finished: false,
  });

  const timerRef = useRef(null);
  const clickTimeoutRef = useRef(null);
  const isMountedRef = useRef(true);

  // Debugging: Log game state updates
  useEffect(() => {
    console.log('Game State Updated:', gameState);
  }, [gameState]);

  // Ensure the component is still mounted
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      // Cleanup any existing timers on unmount
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  /**
   * Reset the reaction game.
   * This helps ensure that the game is in a consistent state whenever it is restarted.
   */
  const resetGame = useCallback(() => {
    setGameState({
      gameStarted: false,
      countdown: 5,
      jumpStart: true,
      time: null,
      finished: false,
    });
    // Clear any existing timers to avoid unintended behavior
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  /**
   * Start the reaction game.
   * Ensures that the game is reset before starting.
   */
  const startReactionGame = useCallback(() => {
    resetGame(); // Ensure game is reset before starting
    setGameState(prevState => ({ ...prevState, gameStarted: true }));
  }, [resetGame]);

  /**
   * Finish reaction game.
   * Calculates the reaction time and updates the state to indicate the game is finished.
   */
  const finishReactionGame = useCallback(() => {
    // Clear any existing timers
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    // Calculate the reaction time if it was not a jump start
    const newTime = gameState.jumpStart ? 0 : (Date.now() - gameState.time) / 1000;
    setGameState(prevState => ({ ...prevState, finished: true, time: newTime }));

    let answers = [...selectedAnswers];
    const answersIndex = answers.findIndex(obj => obj.question === quizTypeReactionGame);

    const newAnswer = {
      question: quizTypeReactionGame,
      answer: { time: newTime, jumpStart: gameState.jumpStart },
    };

    if (answersIndex < 0) {
      answers.push(newAnswer);
    } else {
      answers[answersIndex].answer = newAnswer.answer;
    }

    setSelectedAnswers(answers);
  }, [gameState.jumpStart, gameState.time, selectedAnswers, setSelectedAnswers]);

  useEffect(() => {
    if (
      !gameState.finished &&
      gameState.gameStarted &&
      gameState.countdown !== 0
    ) {
      if (gameState.countdown === 1) {
        // Set a random delay when the last light comes on, between 0.2 to 3 seconds like in Formula 1
        const delay = Math.random() * (3000 - 200) + 200;
        timerRef.current = setTimeout(() => {
          if (isMountedRef.current) {
            setGameState((prevState) => ({
              ...prevState,
              countdown: 0,
              jumpStart: false,
              time: Date.now(),
            }));
          }
        }, delay);
      } else {
        timerRef.current = setTimeout(() => {
          if (isMountedRef.current) {
            setGameState((prevState) => ({
              ...prevState,
              countdown: prevState.countdown - 1,
            }));
          }
        }, 1000);
      }
    }
    // Cleanup timeout on component unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [gameState.gameStarted, gameState.countdown, gameState.finished, gameState.jumpStart]);

  /**
   * Handle button click with debouncing to prevent rapid state changes.
   * Using requestAnimationFrame to ensure state updates happen at the optimal time.
   */
  const handleButtonClick = useCallback(() => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    clickTimeoutRef.current = setTimeout(() => {
      requestAnimationFrame(() => {
        if (!gameState.gameStarted) {
          startReactionGame();
          triggerSaEvent('Reaction game started');
        } else {
          if (!gameState.finished) {
            finishReactionGame();
            triggerSaEvent('Reaction game played');
          } else {
            resetGame();
            triggerSaEvent('Reaction game reset');
          }
        }
      });
    }, 50); // Debounce the click event by 50ms
  }, [gameState.finished, gameState.gameStarted, startReactionGame, finishReactionGame, resetGame]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5">
          <button className="reaction-game" onClick={handleButtonClick}>
            <RaceLights {...gameState} />
            <div className="reaction-game__subtitle-wrapper">
              {(!gameState.gameStarted || gameState.gameStarted || gameState.finished) && (
                <p className="reaction-game__subtitle-wrapper--subtitle d-flex justify-content-center align-items-center rounded p-1">
                  {!gameState.gameStarted && 'Tik of klik op de lampen om te beginnen'}
                  {gameState.gameStarted && !gameState.finished && 'Klik op het startlicht zodra de lichten uit zijn'}
                  {gameState.finished && !gameState.jumpStart && (
                    <>
                      Goed gedaan! Je reageerde in {gameState.time} seconden!
                      <br />
                      Klik opnieuw om het nog eens te proberen
                    </>
                  )}
                  {gameState.finished && gameState.jumpStart && `Tik of klik opnieuw om het nog eens te proberen`}
                </p>
              )}
            </div>
          </button>
        </div>
        <div className="col-12 col-lg-7 ps-lg-5 d-flex align-items-center">
          <div className="reaction-game-intro">
            <h2 className="subtitle">Test je reactietijd</h2>
            <h3 className="title">
              Als coureur moet je bij de start supersnel zijn om de leiding te kunnen pakken. hoe snel reageer jij, als de startlichten uit gaan?
            </h3>
            {gameState.finished && !gameState.jumpStart ? (
              <p className="reaction-game-intro__time finished">{gameState.time}</p>
            ) : (
              <p className="reaction-game-intro__time">0.000</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Racing lights component.
 *
 * @param {number} countdown    The countdown.
 * @param {boolean} gameStarted Whether the game is started.
 * @param {boolean} jumpStart   Whether there was a jump start.
 * @param {boolean} finished    Whether the game is finished.
 */
function RaceLights({ countdown, gameStarted, jumpStart, finished }) {
  return (
    <div className={`reaction-game__lights ${finished && jumpStart ? 'jump-start' : ''}`}>
      {/* Show 5 traffic lights */}
      {[...Array(5).keys()].map(index => {
        const isLightOn = gameStarted && countdown > 0 && index < 6 - countdown;
        return (
          <img
            key={index}
            src={isLightOn ? "/lights-on.png" : "/lights-off.png"}
            className={`light-img ${isLightOn ? 'on' : 'off'}`}
            alt={`Light ${index + 1}`}
            onError={(e) => console.error(`Failed to load image: ${e.target.src}`)}
          />
        );
      })}
    </div>
  );
}
