/**
 *
 * @param {object} error Error object
 * @returns {string} Error message
 */
export function logErrors(error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log('errorCode', errorCode);
    console.log('errorMessage', errorMessage);
    return errorMessage;
}
