export const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL;

export const CAPTCHA_API = 'https://thegameispon-recaptcha-validation-worker.pondigital.solutions/';

export const QUIZ_SLUG = 'quiz';

export const quizTypeQuestion = 'question';
export const quizTypeMemory = 'memory';
export const quizTypeReactionGame = 'reactionGame';
export const quizTypeWhereIsPon = 'whereIsPon';
export const quizTypePuzzle = 'puzzle';

export const STORYBLOK_PREFIX = 'cdn/stories';
export const STORYBLOK_LOCATION = 'the-game-is-pon-dgp/';

export const contentPages = [
  'terms-and-conditions',
  'privacy-statement',
  'contact',
];
