import React from 'react';

/**
 * Alert component
 *
 * @param {object} props - Component props
 * @return {JSX.Element} Alert component
 */
export default function Alert(props) {
  return (
    <div className={`alert rounded-1 alert-${props.type}`} role="alert">
      *{props.message}
    </div>
  );
}
