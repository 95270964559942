import React from 'react';
import { useTranslation } from 'react-i18next';

import Prizes from './Prizes';

export default function Home() {
  const { t } = useTranslation('home');

  return (
    <div className="quiz-day pop position-relative z-2 quiz-day-homepage">
      <div className="container mb-4 position-relative z-1">
        <div className="row">
          <div className="d-flex flex-column align-items-center">
            <div className="col-12 col-md-10 col-lg-6 intro">
              <h1 className="wp-block-heading">{t('intro.heading')}</h1>
              <p className="has-text-align-left">{t('intro.paragraph1')}</p>
              <p className="has-text-align-left">{t('intro.paragraph3')}</p>
            </div>
          </div>
        </div>
      </div>

      <Prizes />
    </div>
  );
}
