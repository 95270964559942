import { transformImage } from '../../helpers/transformImage';

export default function Image({ image, className, alt, ...props }) {
  const src =
    transformImage(image.filename, { width: 640, height: 0 }) + ' 640w';

  const srcSet = [
    transformImage(image.filename, {
      width: 640,
      height: 0,
    }) + ' 640w',
    transformImage(image.filename, { width: 768, height: 0 }) + ' 768w',
    transformImage(image.filename, { width: 1024, height: 0 }) + ' 1024w',
  ].join(', ');

  return (
    <img
      className={className}
      src={src}
      srcSet={srcSet}
      alt={alt || image?.alt}
      {...props}
    />
  );
}
