import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { useInView } from 'react-intersection-observer';
import { usePrizesContext } from '../Contexts/PrizesContext';
import Popup from './Popup';
import scrollLock from '../helpers/scroll-lock';
import { triggerSaEvent } from '../helpers/simpleAnalytics';
import Image from './general/Image';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';

/**
 * Prizes
 *
 * @return {JSX.Element} Prizes component
 */
export default function Prizes() {
  const { t } = useTranslation('prizes');
  const { prizes, isLoadingPrizes, errorLoadingPrizes, fetchPrizes } = usePrizesContext();

  // Fetch the prizes
  useEffect(() => {
    fetchPrizes();
  }, [fetchPrizes]);

  const [showPopup, setShowPopup] = useState({ open: false });
  const swiperRef = useRef(null);

  // Show the right popup and lock scrolling of the body
  function showPrize(id, popupContent) {
    scrollLock.enable();
    setShowPopup({ open: true, id: id, popupContent: popupContent });
  }

  useEffect(() => {
    if (!isLoadingPrizes && !errorLoadingPrizes) {
      // register Swiper custom elements.
      register();

      // Ensure this runs after prizes are set.
      if (prizes && prizes.length > 0) {
        const swiperParams = {
          a11y: true,
          spaceBetween: 20,
          loop: true,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          initialSlide: prizes.length, // Start at the middle of the duplicated array
          loopAdditionalSlides: 3,
          breakpoints: {
            320: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
            1440: {
              slidesPerView: 4.5,
            },
          }
        };

        // Apply swiper parameters and reinitialize
        Object.assign(swiperRef.current, swiperParams);
        swiperRef.current.initialize();
      }
    }
  }, [prizes, isLoadingPrizes, errorLoadingPrizes]);

  // Remove tabindex from header and footer when popup is open.
  useEffect(() => {
    if (showPopup.open) {
      document.querySelector('header').setAttribute('inert', 'true');
      document.querySelector('footer').setAttribute('inert', 'true');
    } else {
      document.querySelector('header').removeAttribute('inert');
      document.querySelector('footer').removeAttribute('inert');
    }
  }, [showPopup]);

  // Setup useInView hook to track visibility of prizes slider
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  // If prizes slider is visible, start autoplay
  useEffect(() => {
    swiperRef?.current?.swiper?.autoplay.stop();
    if (inView) {
      swiperRef?.current?.swiper?.autoplay.start();
    }
  }, [swiperRef, inView]);

  return (
    <div className="prizes position-relative z-1">
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            <h1 className="prizes--title">{t('title')}</h1>
          </div>
        </div>

        {isLoadingPrizes && (
          <div className="row justify-content-center">
            <div className="alert rounded alert-info">{t('loading')}</div>
          </div>
        )}
        {errorLoadingPrizes && (
          <div className="row justify-content-center">
            <div className="alert rounded alert-danger">{t('error')}</div>
          </div>
        )}
      </div>
      <div className="prizes__swiper-wrapper position-relative" ref={ref}>
        <button
          onClick={() => swiperRef.current.swiper.slidePrev()}
          className="prizes-pagination previous rounded-circle position-absolute z-2"
          title="Previous"
        >
          <ChevronLeftIcon />
        </button>
        <button
          onClick={() => swiperRef.current.swiper.slideNext()}
          className="prizes-pagination next rounded-circle position-absolute z-2"
          title="Next"
        >
          <ChevronRightIcon />
        </button>
        <swiper-container init="false" ref={swiperRef}>
          {prizes && [...prizes, ...prizes, ...prizes].map((prize, index) => (
            <swiper-slide key={`${prize.uuid}-${index}`}>
              <div className="prize">
                <div
                  className={`prizes--image rounded ${prize?.content?.prizeType === 'main' ? 'main-prize' : ''}`}
                >
                  <Image image={prize?.content?.image} />
                  {prize?.content?.amount && (
                    <span
                      className={`
                        rounded-circle
                        ${prize?.content?.prizeType === 'consolation' ? 'consolation-prize' : ''}
                      `}
                    >
                      {prize?.content?.amount}x
                    </span>
                  )}
                </div>
                <h4 className="prize__subtitle mb-0">
                  {prize?.content?.subtitle}
                </h4>
                <h4 className="mt-0">{prize?.content?.title}</h4>
                <p>{prize?.content?.description}</p>
                {prize?.content?.popupText && (
                  <>
                    <button
                      className="prizes__info-button"
                      onClick={() => {
                        swiperRef.current.swiper.autoplay.stop();
                        showPrize(prize.uuid, prize?.content?.popupText);
                        triggerSaEvent(`Show prize: ${prize.name}`);
                      }}
                      tabIndex={showPopup.open ? -1 : 0}
                    >
                      {t('more_info')}
                    </button>
                  </>
                )}
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
        {showPopup.open === true && (
          <Popup
            content={showPopup.popupContent}
            setShowPopup={setShowPopup}
            swiperRef={swiperRef}
          />
        )}
      </div>
      <div id="popup-container"></div>
    </div>
  );
}
