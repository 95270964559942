import React from 'react';

export default function DefaultContentPage({title, children}) {
    return (
      <article className="content-page-wrapper">
        <div className="container mb-lg-5 fade-in position-relative z-1">
          <div className="row">
            <div className="titlePage col-12 col-md-10 offset-md-1">
              <h1>{title}</h1>
            </div>
            <div className="contentPage col-12 col-md-10 offset-md-1">
              {children}
            </div>
          </div>
        </div>
      </article>
    );
}
