import { useEffect, useState } from 'react';

import MemoryCard from './MemoryCard';
import { data } from './MemoryData';
import { quizTypeMemory } from '../../Constants';
import { getPreviousAnswer } from '../../helpers/getPreviousAnswer';

export default function MemoryGame({ selectedAnswers, setSelectedAnswers }) {
  const [cards, setCards] = useState([]);
  const [firstCard, setFirstCard] = useState(null);
  const [secondCard, setSecondCard] = useState(null);
  const [won, setWon] = useState(0);
  const [moves, setMoves] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [time, setTime] = useState(null);
  const [ariaMessage, setAriaMessage] = useState('');
  const [showResult, setShowResult] = useState(false);

  // Show the finished result if the user already played the memory game
  useEffect(() => {
    setShowResult(!!getPreviousAnswer(selectedAnswers, quizTypeMemory));
  }, []);

  /**
   * Handle the click of a card.
   *
   * @param {object} selectedCard The card object.
   */
  function handleCardClick(selectedCard) {
    if (!firstCard) {
      setFirstCard(selectedCard);
      return;
    }

    // Prevent double-click on the same card
    // otherwise the game solves itself with a double-click
    if (firstCard === selectedCard) return;

    if (!secondCard) {
      setSecondCard(selectedCard);
    }
  }

  /**
   * Reset the selected cards.
   */
  function resetSelectedCards() {
    setFirstCard(null);
    setSecondCard(null);
    setMoves((prevValue) => prevValue + 1);
  }

  /**
   * Start the memory game.
   */
  function startMemoryGame() {
    setGameStarted(true);
    setTime(Date.now());
  }

  // Shuffle the cards to randomize the positions.
  useEffect(() => {
    const shuffledCards = [...data].sort(() => 0.5 - Math.random());
    setCards(shuffledCards);
  }, []);

  // Stop the time once the player has won the game.
  useEffect(() => {
    if (cards?.length > 0 && cards?.length / 2 === won) {
      const newTime = (Date.now() - time) / 1000;
      setTime(newTime);

      let answers = [...selectedAnswers];
      let answersIndex = answers.findIndex((obj) => obj.question === quizTypeMemory);

      if (answersIndex < 0) {
        answers.push({
          question: quizTypeMemory,
          answer: {
            time: newTime,
            moves,
          },
        });
      } else {
        answers[answersIndex].answer = {
          time: newTime,
          moves,
        };
      }

      setSelectedAnswers(answers);

      setTimeout(() => {
        setShowResult(true);
      }, 1000);
    }
  }, [won]);

  // Handle the card clicks.
  useEffect(() => {
    if (firstCard && secondCard) {
      // Start the game on the first click
      if (!gameStarted) {
        startMemoryGame();
      }

      // Check whether the cards are a correct match.
      if (firstCard.set === secondCard.set) {
        // Update the cards array.
        setCards((prevCards) =>
          prevCards.map((prevCard) => {
            if (prevCard.set === firstCard.set) {
              return { ...prevCard, matched: true };
            } else {
              return prevCard;
            }
          }),
        );
        setWon((prevValue) => prevValue + 1);

        // Voice feedback for accessibility
        setAriaMessage('Paar gevonden!');

        setTimeout(resetSelectedCards, 1000);
      } else {
        setTimeout(resetSelectedCards, 1000);
      }
    }
  }, [firstCard, secondCard]);

  // Clear the ariaMessage after it has been announced
  useEffect(() => {
    if (ariaMessage) {
      const timer = setTimeout(() => {
        setAriaMessage('');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [ariaMessage]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-8 offset-xxl-2">
          <div className="d-flex flex-column">
            <div className="memory position-relative">
              {cards?.length > 0 &&
                cards?.map((card) => (
                  <MemoryCard
                    key={card.id}
                    card={card}
                    handleCardClick={handleCardClick}
                    showCard={
                      card.id === firstCard?.id ||
                      card.id === secondCard?.id ||
                      card.matched || showResult
                    }
                    match={card.matched || showResult}
                    showResult={showResult}
                  />
                ))}

              {showResult && (
                <div className="memory--result fade-in">
                  <p>Alle afbeeldingen zijn gevonden</p>
                </div>
              )}
            </div>
            <div className="mt-4 mb-lg-0">
              <h3 className="title">
                Hoe goed is jouw geheugen?<br />Zoek de afbeeldingen bij elkaar.
              </h3>
            </div>
            {/* Aria live region for accessibility */}
            <div
              aria-live="polite"
              aria-atomic="true"
              className="visually-hidden"
            >
              {ariaMessage}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
