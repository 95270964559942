import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { data } from './PuzzleGameData';
import { quizTypePuzzle } from '../../Constants';
import { getPreviousAnswer } from '../../helpers/getPreviousAnswer';

export default function PuzzleGame({ selectedAnswers, setSelectedAnswers }) {
  const { t } = useTranslation('quiz');
  const [givenAnswer, setGivenAnswer] = useState(null);

  /**
   * Change the selected answer.
   *
   * @param {string} puzzlePiece The ID of the selected puzzle piece.
   */
  function changeSelectedAnswer(puzzlePiece) {
    setGivenAnswer(puzzlePiece);
    let answers = [...selectedAnswers];
    let answersIndex = answers.findIndex(
      (obj) => obj.question === quizTypePuzzle,
    );

    if (answersIndex < 0) {
      answers.push({
        question: quizTypePuzzle,
        answer: puzzlePiece,
      });
    } else {
      answers[answersIndex].answer = puzzlePiece;
    }

    setSelectedAnswers(answers);
  }

  // Set the selected answer if the user has already answered this question.
  useEffect(() => {
    setGivenAnswer(getPreviousAnswer(selectedAnswers, quizTypePuzzle));
  }, []);

  return (
    <div className="container puzzle">
      <div className="row">
        <div className="col-12 col-lg-5 d-md-flex justify-content-start align-items-center mb-4 mb-lg-0">
          <div className="d-flex justify-content-center justify-content-md-start">
            <img src="/puzzle.png" alt="question" className="puzzle__image" />
          </div>
        </div>
        <div className="col-12 col-lg-7 ps-lg-5 d-flex justify-content-center flex-column">
          <h2 className="title fw-medium w-90 mb-4">{t('puzzle.title')}</h2>
          <p className="description mb-4">{t('puzzle.description')}</p>
          <div className="d-flex gap-2">
            {data?.map((piece) => {
              return (
                <button
                  key={piece?.id}
                  className={`button button--lg mb-0 rounded puzzle__piece ${givenAnswer === piece?.id
                    ? 'puzzle__piece--selected pop'
                    : ''
                    }
                  ${givenAnswer && givenAnswer !== piece?.id ? 'puzzle__piece--unselected' : ''}`}
                  onClick={() => changeSelectedAnswer(piece?.id)}
                >
                  <img src={piece?.img} alt={piece?.alt} />
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
