import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSharedQuizContext } from '../Contexts/SharedQuizProvider';
import Quiz from '../Components/quiz/Quiz';
import { QUIZ_SLUG, STORYBLOK_LOCATION, STORYBLOK_PREFIX } from '../Constants';
import { validateEmail } from '../helpers/validate';
import { validateCaptcha } from '../dao/captcha';
import { saveScoreInDatabase } from '../dao/quiz';
import Prizes from '../Components/Prizes';
import { useStoryblokApi } from '@storyblok/react';
import { OTHER_KEY } from '../data/companies';

export default function QuizPage() {
  const { numberOfQuestions, setQuizFinished, setAlreadyAnswered } =
    useSharedQuizContext();
  let quizExpired = true;

  const { t } = useTranslation('quiz');
  const storyblokApi = useStoryblokApi();

  const [noAnswers, setNoAnswers] = useState(false);
  const [notAllQuestionsAnswered, setNotAllQuestionsAnswered] = useState(false);
  const [errors, setErrors] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoadingQuiz, setIsLoadingQuiz] = useState(false);
  const [errorLoadingQuiz, setErrorLoadingQuiz] = useState(false);

  // Get the quiz questions.
  useEffect(() => {
    setIsLoadingQuiz(true);
    setErrorLoadingQuiz(false);

    storyblokApi
      .get(STORYBLOK_PREFIX, {
        starts_with: `${STORYBLOK_LOCATION}questions`,
        version: import.meta.env.VITE_STORYBLOK_CONTENT_STAGE,
        is_startpage: false,
        per_page: 100,
      })
      .then((res) => {
        setQuestions(res?.data?.stories);
      })
      .catch(() => {
        setErrorLoadingQuiz(true);
      });

    setIsLoadingQuiz(false);
  }, []);

  /**
   * Submit the quiz, used in the Quiz component
   *
   * @param {array} user - User data
   * @param {array} selectedAnswers - The selected answers
   * @param {string} value - The captcha token value
   */
  async function submitQuiz(user, selectedAnswers, captchaValue) {
    let formError = false;
    setErrors('');

    // Validate the captcha token
    const result = await validateCaptcha(captchaValue);
    if (!result) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('captcha_not_validated')}`, type: 'captcha' },
      ]);
      return false;
    }

    // Reset states
    setNoAnswers(false);
    setNotAllQuestionsAnswered(false);

    if (!user.firstName) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('firstname_required')}`, type: 'firstName' },
      ]);
    }

    if (!user.lastName) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('lastname_required')}`, type: 'lastName' },
      ]);
    }

    if (!user.email) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('email_required')}`, type: 'email' },
      ]);
    }

    if (validateEmail(user.email) === null) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('email_not_valid')}`, type: 'email' },
      ]);
    }

    if (!user.company) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('company_required')}`, type: 'company' },
      ]);
    }

    if (user.company === OTHER_KEY && !user.companyOther) {
      formError = true;
      setErrors((current) => [
        ...current,
        { text: `${t('other_company_required')}`, type: 'companyOther' },
      ]);
    }

    if (!selectedAnswers.length) {
      setNoAnswers(true);
    } else if (selectedAnswers.length !== numberOfQuestions) {
      setNotAllQuestionsAnswered(true);
    } else if (formError) {
      return false;
    } else {
      saveScoreInDatabase(user, selectedAnswers, QUIZ_SLUG).then((response) => {
        // If the response is false the user already answered the quiz.
        // Due to the Firebase rules it will throw an false because you are not allowed to update a record.
        if (!response) {
          setAlreadyAnswered(true);
          return false;
        } else {
          setNoAnswers(false);
          setQuizFinished(true);
        }

        // Scroll to the top of the page
        document.body.scrollIntoView({
          behavior: 'smooth',
        });
      });
    }
  }

  return (
    <div className="quiz-day">
      {errorLoadingQuiz && (
        <div className="d-flex justify-content-center mt-5">
          <div className="alert rounded alert-danger">
            {t('error_loading_quiz')}
          </div>
        </div>
      )}

      {!isLoadingQuiz && !errorLoadingQuiz && (
        <Quiz
          questions={questions}
          submitQuiz={submitQuiz}
          noAnswers={noAnswers}
          quizExpired={quizExpired}
          notAllQuestionsAnswered={notAllQuestionsAnswered}
          errors={errors}
        />
      )}

      <Prizes />
    </div>
  );
}
