import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { render } from 'storyblok-rich-text-react-renderer';
import scrollLock from '../helpers/scroll-lock';

/**
 * Popup
 *
 * @return {JSX.Element} Popup component
 */
export default function Popup({ content, setShowPopup, swiperRef }) {
  // Hide the popup and remove locked scroll
  function closePopup(e) {
    // Only close the popup if the user clicks the close button or presses the escape key.
    if (e.key === 'Escape' || e.type === 'click') {
      swiperRef.current.swiper.autoplay.start();
      scrollLock.disable();
      setShowPopup({ open: false });
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', closePopup);
    const popup = document.getElementById('popup-container');

    // Setting initial focus on close button
    const closeModalBtn = document.getElementById('popup__close-button');
    closeModalBtn.focus();

    // Find all focusable elements within the modal
    const activeElements = popup.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled])',
    );

    // Get the first and last focusable elements
    const firstFocusableElement = activeElements[0];
    const lastFocusableElement = activeElements[activeElements.length - 1];

    // Event listener to trap focus when Shift + Tab is pressed on the first focusable element
    firstFocusableElement.addEventListener('keydown', function (e) {
      if (e.which == 9 && e.shiftKey) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    });

    // Event listener to trap focus when Tab is pressed on the last focusable element
    lastFocusableElement.addEventListener('keydown', function (e) {
      if (e.which == 9 && !e.shiftKey) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    });
  }, []);

  return (
    <>
      {createPortal(
        <div className="popup--overlay">
          <div id="popup" className="popup rounded" open>
            <button
              id="popup__close-button"
              className="popup__close-button"
              onClick={(e) => closePopup(e, 'close')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <div className="popup--content">
              {render(content)}
            </div>
          </div>
        </div>,
        document.getElementById('popup-container'),
      )}
    </>
  );
}
