import { CAPTCHA_API } from '../Constants';

/**
 * Validate the captcha token in the backend
 *
 * @param {string} token - The captcha token value
 */
export async function validateCaptcha(token) {

  return fetch(CAPTCHA_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: token,
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error(response);
      }
      return response.json();
    });
}
