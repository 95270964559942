import { Link } from 'react-router-dom';

/**
 * Progress bar
 *
 * @param {int} progress - Progress of the quiz
 * @param {boolean} lastStep - Last step yes or no
 * @param {object} activeQuestion - Current question
 *
 * @return {JSX.Element} Progress bar
 */
export default function ProgressBar({ progress, lastStep }) {
  return (
    <div className="progress-container align-self-center">
      <div className="position-relative z-2 align-items-center d-flex justify-content-between h-100">
        <Link to="/" title="Home" className="progress-logo">
          <img src="/pon-logo.svg" alt="Pon" />
        </Link>
      </div>
    </div>
  );
}
