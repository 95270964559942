export default function MemoryCard({ card, handleCardClick, showCard, match, showResult }) {
  return (
    <button
      className={`memory-card matched ${showCard ? 'show-front' : 'show-back'} ${match && 'show'} ${showResult && 'finished'} `}
      onClick={() => handleCardClick(card)}
    >
      <div className="memory-card-content">
        {showCard && (
          <img
            src={card?.image}
            alt={match ? `Paar gevonden van ${card?.name} ` : `${card?.name}`}
          />
        )}
      </div>
    </button>
  );
}
