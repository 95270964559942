export const data = [
  {
    id: 'a',
    img: '/puzzle-piece-a.png',
    alt: 'puzzle piece a',
  },
  {
    id: 'b',
    img: '/puzzle-piece-b.png',
    alt: 'puzzle piece b',
  },
  {
    id: 'c',
    img: '/puzzle-piece-c.png',
    alt: 'puzzle piece c',
  },
];
