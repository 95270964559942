import { firestore } from '../firebase';

export async function saveScoreInDatabase(user, answers, slug) {
  const nowDate = new Date();
  const date = nowDate.toLocaleString('nl-nl', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  const timestamp = nowDate.toLocaleString('nl-nl', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  const timestampString = nowDate.toTimeString();

  if (user && answers) {
    try {
      await firestore
        .collection(slug)
        .doc(user.email)
        .set({
          firstname: user.firstName ? user.firstName : '',
          lastName: user.lastName ? user.lastName : '',
          email: user.email ? user.email : '',
          company: user.company ? user.company : '',
          companyOther: user.companyOther ? user.companyOther : '',
          givenAnswers: answers,
          date: date,
          timestamp: timestamp,
          timestampString: timestampString,
        });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
