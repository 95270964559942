import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useSharedQuizContext } from '../../Contexts/SharedQuizProvider';
import QuizForm from './QuizForm';
import QuizQuestion from './QuizQuestion';
import { getQuizOrder } from './QuizOrder';
import { useTranslation } from 'react-i18next';
import MemoryGame from '../memoryGame/MemoryGame';
import ReactionGame from '../reactionGame/ReactionGame';
import WhereIsPonGame from '../whereIsPonGame/WhereIsPonGame';
import {
  quizTypeMemory,
  quizTypePuzzle,
  quizTypeQuestion,
  quizTypeReactionGame,
  quizTypeWhereIsPon,
} from '../../Constants';
import PuzzleGame from '../puzzleGame/PuzzleGame';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import { triggerSaEvent } from '../../helpers/simpleAnalytics';

/**
 * Quiz
 *
 * @param {object} questions - The quiz data
 * @param {function} submitQuiz - Submit the quiz
 * @param {boolean} noAnswers - Indicates if there are no answers
 * @param {boolean} quizExpired - Indicates if the quiz has expired
 * @param {boolean} notAllQuestionsAnswered - Indicates if not all questions have been answered
 * @param {object} errors - Contains error messages when there are errors
 * @param {number} numberOfQuestions - The total number of questions in the quiz
 * @param {function} setProgress - Function to set the progress of the quiz
 *
 * @return {JSX.Element} Quiz component
 */
export default function Quiz({
  questions,
  submitQuiz,
  noAnswers,
  quizExpired,
  notAllQuestionsAnswered,
  errors,
}) {
  const {
    progress,
    alreadyAnswered,
    quizFinished,
    numberOfQuestions,
    setProgress,
    setActiveQuestionType,
  } = useSharedQuizContext();

  if (quizExpired) {
    return Redirect('/');
  }

  const quizRef = useRef(null);
  const quizWrapperRef = useRef(null);

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState('quiz-fade-in');
  const [showContent, setShowContent] = useState(true);
  const { t } = useTranslation('quiz');

  const questionList = getQuizOrder(questions)

  // Derive the active question
  const activeQuestion = questionList[currentQuestionIndex];

  // Derive whether the question is answered
  const hasAnsweredQuestion =
    (typeof selectedAnswers[currentQuestionIndex] === 'undefined') === false;

  // Update when the quiz changes
  useEffect(() => {
    // Update progress bar
    // guard against going out of bounds
    if (currentQuestionIndex <= numberOfQuestions) {
      setProgress(currentQuestionIndex + 1);
    }

    // Scroll to the top of the page
    document.body.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentQuestionIndex, numberOfQuestions]);

  // Set the type of the active question
  useEffect(() => {
    setActiveQuestionType(activeQuestion?.type);
  }, [activeQuestion]);

  /**
   * event handler for quiz pagination
   */
  function handlePagination(direction, numQuestions) {
    setTransitionClass('quiz-fade-out');

    setTimeout(() => {
      setShowContent(false); // Hide content after fade-out animation
      setCurrentQuestionIndex((prevState) => {
        if (
          (direction === 'previous' && prevState === 0) ||
          (direction === 'next' && prevState === numQuestions)
        ) {
          return prevState;
        }
        return direction === 'next' ? prevState + 1 : prevState - 1;
      });
    }, 500); // Ensure this matches the animation duration
  }

  useEffect(() => {
    if (!showContent) {
      const timeout = setTimeout(() => {
        setShowContent(true); // Show content after question index changes
        setTransitionClass('quiz-fade-in'); // Trigger fade-in animation
      }, 10); // A short delay to ensure the content is hidden before fade-in

      return () => clearTimeout(timeout);
    }
  }, [showContent, currentQuestionIndex]);

  /**
   * @param {number} index - The answer index
   * @param {number} id - The question id
   * @param {string} title - Title of the question
   * @param {string} answer - Answer of the given question
   */
  function changeAnswer(index, id, title, answer) {
    let answers = [...selectedAnswers];
    let answersIndex = answers.findIndex((obj) => obj.question === id);

    if (answersIndex < 0) {
      answers.push({
        question: id,
        questionTitle: title,
        answer: index,
        answerValue: answer,
        answerChoice: ['a', 'b', 'c', 'd'][index],
      });
    } else {
      answers[answersIndex].answer = index;
      answers[answersIndex].answerValue = answer;
      answers[answersIndex].answerChoice = ['a', 'b', 'c', 'd'][index];
    }

    setSelectedAnswers(answers);
  }

  const adjustPaginationButtonPadding = () => {
    const element = quizWrapperRef.current;
    if (!element) return;

    const header = document.querySelector('header');
    const width = window.innerWidth;
    const height = element.getBoundingClientRect().height;
    const headerHeight = header ? header.getBoundingClientRect().height : 0;
    const viewportHeight = window.innerHeight - headerHeight;

    if (width >= 768) {
      if (height > viewportHeight) {
        document.documentElement.style.setProperty('--pagination-padding-bottom', '3rem');
      } else {
        document.documentElement.style.setProperty('--pagination-padding-bottom', '0px');
      }
    } else {
      document.documentElement.style.setProperty('--pagination-padding-bottom', '0px');
    }
  };

  // Adjust padding-bottom based on the element's height and window resize
  useEffect(() => {
    adjustPaginationButtonPadding();

    const handleResize = () => {
      adjustPaginationButtonPadding();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentQuestionIndex, progress]);

  // GIVEN: quiz is finished THEN: show success message
  if (quizFinished) {
    return (
      <div className="container position-relative z-1">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6 intro">
            <h1 className="wp-block-heading">{t('quizFinished.title')}</h1>
            <p className="has-text-align-left">
              {t('quizFinished.description')}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={quizRef}
      className={`quiz ${progress > numberOfQuestions ? 'no-bg' : ''}`}
    >
      {alreadyAnswered && (
        <div className="container position-relative z-1 quiz--status__danger">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6 intro">
              <h1 className="wp-block-heading">{t('alreadyAnswered.title')}</h1>
              <p className="has-text-align-left">
                {t('alreadyAnswered.description')}
              </p>
            </div>
          </div>
        </div>
      )}
      {activeQuestion && !alreadyAnswered && (
        <>
          <div ref={quizWrapperRef} className="quiz__center">
            <div className={`quiz__wrapper pt-3 pt-lg-0 ${activeQuestion?.type === quizTypeMemory && 'memory-question'}`}>
              <div className={`position-relative z-1 ${transitionClass}`}>
                {showContent && (
                  <div className="justify-content-center">
                    {activeQuestion?.type === quizTypeQuestion && (
                      <QuizQuestion
                        questionData={activeQuestion}
                        changeAnswer={changeAnswer}
                        quizExpired={quizExpired}
                        selectedAnswers={selectedAnswers}
                        key={activeQuestion.id}
                      />
                    )}

                    {activeQuestion?.type === quizTypeMemory && (
                      <MemoryGame
                        selectedAnswers={selectedAnswers}
                        setSelectedAnswers={setSelectedAnswers}
                      />
                    )}

                    {activeQuestion?.type === quizTypeReactionGame && (
                      <div className="mb-5">
                        <ReactionGame
                          selectedAnswers={selectedAnswers}
                          setSelectedAnswers={setSelectedAnswers}
                        />
                      </div>
                    )}

                    {activeQuestion?.type === quizTypeWhereIsPon && (
                      <WhereIsPonGame
                        selectedAnswers={selectedAnswers}
                        setSelectedAnswers={setSelectedAnswers}
                      />
                    )}
                    {activeQuestion?.type === quizTypePuzzle && (
                      <PuzzleGame
                        selectedAnswers={selectedAnswers}
                        setSelectedAnswers={setSelectedAnswers}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Quiz Pagination */}
            <div className={`quiz--pagination container z-2 ${transitionClass}`} style={{ paddingBottom: 'var(--pagination-padding-bottom)' }}>
              <div className={`row ${currentQuestionIndex !== 0 ? 'justify-content-between' : 'justify-content-end'}`}>
                {/* GIVEN current index is not 0 THEN show previous button */}
                {currentQuestionIndex !== 0 && (
                  <div className="col-6 col-md-5 col-lg-4 text-end d-flex">
                    <button
                      onClick={() => {
                        handlePagination('previous', numberOfQuestions);
                        triggerSaEvent(`Click on: ${t('previous_button_label')} button`);
                      }}
                      className="button button--white pagination py-3 px-6"
                      value="previous"
                      title={t('previous_button_label')}
                    >
                      <ArrowLeftIcon />
                      <span>{t('previous_button_label')}</span>
                    </button>
                  </div>
                )}
                {/* GIVEN current index is less than number of questions THEN show next button */}
                {/* GIVEN selected answer is undefined THEN next button is disabled */}
                {currentQuestionIndex <= numberOfQuestions - 1 && (
                  <div className="col-6 col-md-5 col-lg-4 text-start d-flex justify-content-end">
                    <button
                      onClick={() => {
                        handlePagination('next', numberOfQuestions);
                        currentQuestionIndex < numberOfQuestions - 1
                          ? triggerSaEvent(`Click on: ${t('next_button_label')} button`)
                          : triggerSaEvent(`Click on: ${t('complete_game_label')} button`)
                      }}
                      className={`button button--white pagination text-end py-3 px-6 ${hasAnsweredQuestion ? '' : 'button--secondary-disabled'}`}
                      value="next"
                      disabled={hasAnsweredQuestion === false}
                      title={
                        currentQuestionIndex < numberOfQuestions - 1
                          ? t('next_button_label')
                          : t('complete_game_label')
                      }
                    >
                      <span>
                        {currentQuestionIndex < numberOfQuestions - 1
                          ? t('next_button_label')
                          : t('complete_game_label')}
                      </span>
                      <ArrowRightIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* Quiz Submit */}
      {/* Show form when user is at the end of the quiz */}
      {currentQuestionIndex === numberOfQuestions && !alreadyAnswered && (
        <QuizForm
          selectedAnswers={selectedAnswers}
          submitQuiz={submitQuiz}
          notAllQuestionsAnswered={notAllQuestionsAnswered}
          noAnswers={noAnswers}
          errors={errors}
        />
      )}
    </div>
  );
}
